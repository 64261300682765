import 'babel-polyfill';
import './src/index.scss';

import { injectTwitterScript } from './src/utils/twitterWidget';

let injectedTwitterScript = false;

export default function onClientEntry() {
  // Don't need to do anything here, but if you don't
  // export something, the import won't work.
}

export function onRouteUpdate() {
  // If there's an embedded tweet, lazy-load the twitter script (if it hasn't
  // already been loaded), and then run the twitter load function.
  if (window.document.querySelector('.twitter-timeline') !== null) {
    if (!injectedTwitterScript) {
      injectTwitterScript();
      injectedTwitterScript = true;
    }

    if (
      typeof twttr !== 'undefined'
      && window.twttr.widgets
      && typeof window.twttr.widgets.load === 'function'
    ) {
      window.twttr.widgets.load();
    }
  }
}
