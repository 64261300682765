module.exports = [{
      plugin: require('A:/Projects/FAO/AQUASTAT/_imp/fao-aquastat-portal/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('A:/Projects/FAO/AQUASTAT/_imp/fao-aquastat-portal/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":"20","maintainCase":false,"icon":false},
    },{
      plugin: require('A:/Projects/FAO/AQUASTAT/_imp/fao-aquastat-portal/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-127750167-2"},
    },{
      plugin: require('A:/Projects/FAO/AQUASTAT/_imp/fao-aquastat-portal/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
