// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-template-js": () => import("A:\\Projects\\FAO\\AQUASTAT\\_imp\\fao-aquastat-portal\\src\\templates\\pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-pages-404-js": () => import("A:\\Projects\\FAO\\AQUASTAT\\_imp\\fao-aquastat-portal\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activities-js": () => import("A:\\Projects\\FAO\\AQUASTAT\\_imp\\fao-aquastat-portal\\src\\pages\\activities.js" /* webpackChunkName: "component---src-pages-activities-js" */),
  "component---src-pages-climate-info-tool-js": () => import("A:\\Projects\\FAO\\AQUASTAT\\_imp\\fao-aquastat-portal\\src\\pages\\climate-info-tool.js" /* webpackChunkName: "component---src-pages-climate-info-tool-js" */),
  "component---src-pages-countries-and-basins-js": () => import("A:\\Projects\\FAO\\AQUASTAT\\_imp\\fao-aquastat-portal\\src\\pages\\countries-and-basins.js" /* webpackChunkName: "component---src-pages-countries-and-basins-js" */),
  "component---src-pages-data-analysis-js": () => import("A:\\Projects\\FAO\\AQUASTAT\\_imp\\fao-aquastat-portal\\src\\pages\\data-analysis.js" /* webpackChunkName: "component---src-pages-data-analysis-js" */),
  "component---src-pages-databases-js": () => import("A:\\Projects\\FAO\\AQUASTAT\\_imp\\fao-aquastat-portal\\src\\pages\\databases.js" /* webpackChunkName: "component---src-pages-databases-js" */),
  "component---src-pages-es-js": () => import("A:\\Projects\\FAO\\AQUASTAT\\_imp\\fao-aquastat-portal\\src\\pages\\es.js" /* webpackChunkName: "component---src-pages-es-js" */),
  "component---src-pages-fr-js": () => import("A:\\Projects\\FAO\\AQUASTAT\\_imp\\fao-aquastat-portal\\src\\pages\\fr.js" /* webpackChunkName: "component---src-pages-fr-js" */),
  "component---src-pages-geospatial-information-js": () => import("A:\\Projects\\FAO\\AQUASTAT\\_imp\\fao-aquastat-portal\\src\\pages\\geospatial-information.js" /* webpackChunkName: "component---src-pages-geospatial-information-js" */),
  "component---src-pages-index-js": () => import("A:\\Projects\\FAO\\AQUASTAT\\_imp\\fao-aquastat-portal\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-overview-js": () => import("A:\\Projects\\FAO\\AQUASTAT\\_imp\\fao-aquastat-portal\\src\\pages\\overview.js" /* webpackChunkName: "component---src-pages-overview-js" */),
  "component---src-pages-publications-js": () => import("A:\\Projects\\FAO\\AQUASTAT\\_imp\\fao-aquastat-portal\\src\\pages\\publications.js" /* webpackChunkName: "component---src-pages-publications-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "A:\\Projects\\FAO\\AQUASTAT\\_imp\\fao-aquastat-portal\\.cache\\data.json")

