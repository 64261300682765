export const injectTwitterScript = () => {
  const s = window.document.createElement('script');
  s.type = 'text/javascript';
  s.innerText = `
window.twttr = (function(d, s, id) {
  var js, fjs = d.getElementsByTagName(s)[0],
    t = window.twttr || {};
  if (d.getElementById(id)) return t;
  js = d.createElement(s);
  js.id = id;
  js.src = "https://platform.twitter.com/widgets.js";
  fjs.parentNode.insertBefore(js, fjs);
  t._e = [];
  t.ready = function(f) {
    t._e.push(f);
  };
  return t;
}(document, "script", "twitter-wjs"));
`;
  window.document.getElementsByTagName('head')[0].appendChild(s);
};

export default injectTwitterScript;
